$short: 500px;
$sm: 600px;
$md: 768px;
$lg: 1024px;
$light-yellow: #ffe9a5;
$primary: #b4cbd3;
$dark-primary: #7ca9b9;
$muted-yellow: #726b56;
$dark-gray: rgb(27,27,27);

@font-face {
  font-family: Sketchy;
  src: url('fonts/SketchyRegular.otf') format('opentype');
}

@font-face {
  font-family: SketchyDecorative;
  src: url('fonts/SketchyDecorative.otf') format('opentype');
}

html, body {
  font-family: 'Sketchy', serif;
  background-color: $dark-gray;
}

h1,
.decorative {
  font-family: 'SketchyDecorative', serif;
}

a,
button {
  font-size: 30px;
  font-family: 'SketchyDecorative', serif;
  border: 3px solid $primary;
  background: $primary;
  color: #000;
  text-decoration: none;
  padding: 10px 13px;
  transition: all .2s ease-in-out;
  line-height: 1;
  display: inline-block;
  cursor: pointer;

  @media (min-width: $md) {
    border-radius: 5px;
    padding: 14px 18px;
  }

  @media (min-width: $lg) {
    font-size: 40px;
  }

  &:hover {
    background: $dark-primary;
  }
}

h2 {
  position: relative;
  font-size: 30px;
  margin-bottom: 10px;

  @media (min-width: $md) {
    font-size: 60px;
  }
}

p {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
  position: relative;
  margin: auto;
  max-width: 90%;

  @media (min-width: $md) {
    font-size: 20px;
  }
}

.intro {
  background: $primary;
  display: grid;
  align-items: center;
  text-align: center;

  > div {
    max-width: 600px;
    margin: auto;
  }


  h2, p {
    font-family: 'SketchyDecorative', serif;
    max-width: 70%;
    margin: auto;
    opacity: .75;
  }

  p {
    padding-bottom: 30px;
  }
}

.secondary-url {
  position: absolute;
  bottom: 0;
  text-align: right;
  z-index: 1;
  display: grid;

  @media (max-width: $md) {
    opacity: .9;
    grid-template-rows: 1fr 1fr;
    z-index: 10;

    .nav-buttons {
      grid-row-start: 2
    }
  }

  @media (min-width: $md) {
    position: relative;
    grid-template-columns: 1fr 1fr;
  }

  .nav-buttons {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    align-items: center;
    align-content: center;

    @media (min-width: $md) {
      position: unset;
      width: unset;
      text-align: left;
      gap: 10px;
    }

    button {
      cursor: pointer;
    }
  }
}

.pages,
.secondary-url {
  width: 100%;
  margin: auto;

  @media (min-width: $md) {
    margin: 30px auto;
    width: 90%;
    max-width: 1300px;
  }

  // short height
  @media (max-height: $short) {
    margin: 10px auto;
  }
}

.pages {
  position: relative;
  height: 844px;
  max-height: 100%;
  //z-index: 2;

  @media (min-width: $sm) {
    height: 600px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }

  // short height
  @media (max-height: $short) {
    height: 310px;
  }

  .page {
    height: 100%;
    width: 100%;
    user-select: none;
    touch-action: none;
    background-color: $primary;
    text-align: center;
    justify-content: center;
    overflow: hidden;
    //user-select: none;
    //touch-action: none;

    @media ( min-width: $sm ) {
      border-radius: 5px;
    }

    > div {
      overflow: hidden;
    }

    @media ( min-width: $md ) {
      max-height: 600px;
      display: grid;
      grid-template-rows: unset;
      grid-template-columns: 50% 50%;
      align-items: center;
      align-content: center;
    }

    .animated {
      position: absolute;
      top: 17px;
      left: -3%;
      max-width: 120%;
      fill: #c8dde4;
      animation: CloudMotionMobile 5s ease-in-out infinite;

      @media ( min-width: $md ) {
        top: -390px;
        left: 53%;
        max-width: 60%;
        animation: CloudMotion 5s ease-in-out infinite;
      }
    }

    h1 {
      position: absolute;
      font-size: 400px;
      margin: 0;
      bottom: 200px;
      left: 25%;
      color: $dark-primary;
      opacity: .7;
      //z-index: 0;
      line-height: 0;

      @media ( min-width: $md ) {
        font-size: 840px;
        top: 200px;
        bottom: unset;
        left: 55%;
      }

      // short height
      @media (max-height: $short) {
        font-size: 300px;
        top: 100px;
      }
    }

    img {
      pointer-events: none;
      max-width: 100%;
      max-height: 100%;
      height: auto;

      &.not-collected {
        opacity: 0.2;
      }

      @media (min-width:$md) {
        //z-index: unset;
      }

      // short height
      @media (max-height: $short) {
        max-height: 280px;
        margin: auto;
      }
    }

    &.posts {
      text-align: left;

      .brand-panel {
        display: none;
        text-align: center;

        @media (min-width: $md) {
          display: unset;
        }
      }

      > .posts-content {
        height: 100%;
        overflow-y: auto !important;

        > div {
          display: none;

          @media (min-width: $md) {
            display: unset;
          }
        }

        > div:first-child {
          display: unset;
        }


        @media (min-width: $lg) {
          &.intro-copy {
            display: grid;
            align-content: center;
          }
        }
      }

      svg {
        margin-right: 16px;
      }

      .inside-logo {
        margin: auto;
        width: 220px;

        @media (min-width: $md) {
          width: 300px;
        }

        @media (min-width: $lg) {
          width: 400px;
        }
      }

      h2 {
        max-width: 91%;
        margin: 50px auto 0;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
}

section.empty {
  width: 250px;
  height: 250px;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  border: dashed 7px $dark-primary;
  border-radius: 10px;
  align-items: center;
  align-content: center;
  display: grid;

  @media (min-width: $md) {
    height: 450px;
    width: 450px;
    max-width: 90%;
    max-height: 90%;
  }

  @media (min-width: $lg) {
    height: 600px;
    width: 600px;
  }

  p a {
    font-size: 50px;

    @media (min-width: $md) {
      font-size: 80px;
    }
  }

  // short height
  @media (max-height: $short) {
    height: 200px;
    width: 200px;

    p {
      font-size: 100px;
    }
  }
}
.collection-urls {

  @media (max-width: $md) {
    position: relative;
    z-index: 10;
    background: transparent !important;
    border: none !important;
    display: block;
    text-align: center;
  }
}

.link {
  text-decoration: underline;
}

.collect {
  position: absolute;
  top: 20%;
  left: 3%;
  width: 44%;
  display: block;
  text-align: center;
}

.twitter {
  text-align: center;
}

.text-center {
  text-align: center;
}
@keyframes CloudMotionMobile {
  0%, 100% {
    top: 17px;
  }
  50% {
    top: 30px;
  }
}

@keyframes CloudMotion {
  0%, 100% {
    top: -390px;
  }
  50% {
    top: -370px;
  }
}